import { userManager } from "./user-manager";

const isLoginRoute = globalThis.location.pathname === "/login";
const isSilentLoginRoute = globalThis.location.pathname === "/login/silent";
const isLogoutRoute = globalThis.location.pathname === "/logout";
const isLogoutCallbackRoute =
  globalThis.location.pathname === "/logout/callback";

const { REACT_APP_WEBSITE_URL: WEBSITE_URL, NODE_ENV } = process.env;
const referrerKey = "referrer";

void (async function () {
  const referrer = globalThis.document.referrer;

  if (referrer.includes("waitcure.com") || referrer.includes("zymego.com")) {
    globalThis.localStorage.setItem(referrerKey, referrer);
  }

  await userManager.clearStaleState();
  let user: Awaited<ReturnType<typeof userManager.getUser>> | void =
    await userManager.getUser();

  if (isLogoutRoute) {
    userManager.signoutRedirect();

    return;
  }

  if (isLogoutCallbackRoute) {
    userManager.signoutCallback();
    const url =
      globalThis.localStorage.getItem(referrerKey) ??
      WEBSITE_URL ??
      "https://zymego.com";
    globalThis.localStorage.removeItem(referrerKey);
    globalThis.location.replace(url);
    return;
  }

  const pageAccessedByReload = globalThis.performance
    .getEntriesByType("navigation")
    .map((nav) => nav.type)
    .includes("reload");

  if (
    !user &&
    !pageAccessedByReload &&
    !isLoginRoute &&
    !isSilentLoginRoute &&
    NODE_ENV !== "development" &&
    globalThis.location.hostname !== "customer.staging.zymego.app" &&
    globalThis.location.hostname !== "customer.zymego.app" && // TODO: We should probably remove this once we have a link from zymego.com
    referrer.includes("waitcure.com") === false &&
    referrer.includes("zymego.com") === false &&
    referrer.includes("zymego.app") === false
  ) {
    const url = WEBSITE_URL ?? "https://zymego.com";
    globalThis.location.replace(url);
    return;
  }

  if (isLoginRoute || isSilentLoginRoute) {
    try {
      user = await userManager.signinCallback();

      if (isSilentLoginRoute) {
        return;
      }
    } catch (error) {
      if (user?.state !== "retry") {
        void userManager.signinRedirect({ state: "retry" });
        return;
      }

      // TODO: Render nice error view
      console.error({ error });
      void userManager.clearStaleState();
      return;
    }
  }

  if (user) {
    void import("./init");
    globalThis.history.replaceState(undefined, "", "/");
    return;
  }

  void userManager.signinRedirect();
})();
